import React, { useState } from 'react';
import { Button, Grid, Link, TextField, Typography, CircularProgress, IconButton } from '@mui/material';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import MaterialReactTable from 'material-react-table';
import { OeoAnnotatorDescription } from './OeoAnnotatorDescription';
import { HighlightedLabel, InputContainer, HelperTextContainer, HelperText, CopiedText, AnnotatedText, ScrollableText, ButtonContainer } from './styledComponent';
import { generateLightColor } from './utils';
import { createColumns } from './columns';



export const Annotator = () => {
    const [inputText, setInputText] = useState('');
    const [matches, setMatches] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [highlightedItems, setHighlightedItems] = useState([]);
    const [annotatedText, setAnnotatedText] = useState('');
    const [copied, setCopied] = useState(false);

    // Create a color map for each matched term
    const [termColors, setTermColors] = useState({});

    const handleInputChange = (e) => setInputText(e.target.value);

    const handleAnnotate = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_OEOANNOTATOR_BACKEND_URL}/annotate`, { 
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ text: inputText }),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const data = await response.json();
            setMatches(data.matches);
            setAnnotatedText(inputText);
    
            // Generate unique light color for each term and update state
            const colorMap = {};
            data.matches.forEach((match) => {
                if (!colorMap[match.matched_term]) {
                    colorMap[match.matched_term] = generateLightColor();
                }
            });
            setTermColors(colorMap);
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred while annotating the text. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleHover = (match) => setHighlightedItems([match]);

    const handleMouseLeave = () => setHighlightedItems([]);

    const highlightText = () => {
        if (!annotatedText || matches.length === 0) return annotatedText;
    
        let parts = [];
        let lastIndex = 0;
    
        // Sort matches by start position and length (descending) to handle overlaps correctly
        const sortedMatches = [...matches].sort((a, b) => {
            if (a.start === b.start) {
                return b.end - b.start - (a.end - a.start);
            }
            return a.start - b.start;
        });
    
        sortedMatches.forEach((match, index) => {
            if (match.start >= lastIndex) {
                parts.push(annotatedText.slice(lastIndex, match.start));
                parts.push(
                    <HighlightedLabel
                        key={`label_${index}`}
                        isHovered={highlightedItems.some(item => item.start === match.start && item.end === match.end)}
                        onMouseEnter={() => handleHover(match)}
                        onMouseLeave={handleMouseLeave}
                        color={termColors[match.matched_term]} // Apply the unique light color for the term
                    >
                        {annotatedText.slice(match.start, match.end)}
                    </HighlightedLabel>
                );
                lastIndex = match.end;
            }
        });
    
        parts.push(annotatedText.slice(lastIndex));
        return parts;
    };
    
    const handleCopyText = () => {
        const exampleText = 'electrical energy is a form of energy derived from the potential or kinetic energy of charged particles.';
        navigator.clipboard.writeText(exampleText);
        setInputText(exampleText);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const handleReset = () => {
        setInputText('');
        setMatches([]);
        setAnnotatedText('');
    };

    // Remove duplicates from the matches based on the matched term
    const getUniqueMatches = () => {
        const seen = new Set();
        return matches.filter((match) => {
            if (seen.has(match.matched_term)) {
                return false;
            }
            seen.add(match.matched_term);
            return true;
        });
    };



    return (
        <Grid container justifyContent="center">
            <Grid item md={11}>
                <OeoAnnotatorDescription/>
                {!annotatedText ? (
                    <InputContainer>
                        <TextField
                            label="Enter Text"
                            variant="outlined"
                            multiline
                            rows={6}
                            fullWidth
                            value={inputText}
                            onChange={handleInputChange}
                            helperText={
                                <HelperTextContainer>
                                    <HelperText>
                                        <b>Example Text:</b> 
                                        <i> electrical energy is a form of energy derived from the potential or kinetic energy of charged particles.</i>
                                    </HelperText>
                                    <IconButton onClick={handleCopyText}>
                                        <FileCopyOutlinedIcon />
                                    </IconButton>
                                    {copied && <CopiedText>Copied</CopiedText>}
                                </HelperTextContainer>
                            }
                        />
                    </InputContainer>
                ) : (
                    <AnnotatedText>
                        <Typography variant="h6">Annotated Text</Typography>
                        <ScrollableText>{highlightText()}</ScrollableText>
                    </AnnotatedText>
                )}
                <ButtonContainer>
                    <Button variant="contained" color="primary" onClick={handleAnnotate}>
                        Annotate
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleReset}>
                        Reset
                    </Button>
                </ButtonContainer>
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <MaterialReactTable
                        columns={createColumns(inputText)}
                        data={getUniqueMatches()}
                        initialState={{
                            pagination: { pageSize: 10 },
                            density: 'compact',
                        }}
                        renderTopToolbarCustomActions={() => (
                            <Typography variant="h6" gutterBottom>
                                Matched Terms
                            </Typography>
                        )}
                    />
                )}
            </Grid>
        </Grid>
    );
};


