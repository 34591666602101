// styledComponents.js
import styled from 'styled-components';

export const InputContainer = styled.div`
    margin-bottom: 20px;
`;

export const HelperTextContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const HelperText = styled.div`
    margin-right: 8px;
`;

export const CopiedText = styled.span`
    color: #4caf50;
    font-weight: bold;
`;

export const ButtonContainer = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const AnnotatedText = styled.div`
    margin-bottom: 20px;
`;

export const ScrollableText = styled.div`
    height: 180px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    background-color: #f9f9f9;
`;

export const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
`;

export const ResultsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
`;

export const HighlightedLabel = styled.span`
    background-color: ${({ isHovered, color }) => (isHovered ? '#ffeb3b' : color)};
    cursor: pointer;
    padding: 2px 4px;
    border-radius: 4px;
    transition: background-color 0.3s;

    &:hover {
        background-color: #ffeb3b;
    }
`;
