import React, {useState} from "react";
import {Button, Grid, ListItem, TextField, Typography} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";

const OPENAI_BASE_URL = "https://api.openai.com/v1";

const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY
const ASSISTANT_ID = process.env.REACT_APP_OPENAI_ASSISTANT_ID;

const HEADERS = {
    Authorization: `Bearer ${OPENAI_API_KEY}`,
    "Content-Type": "application/json",
    "OpenAI-Beta": "assistants=v2",
};

const SubjectIndexer = () => {
    const [formData, setFormData] = useState({
        input: "",
    });
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState(null);

    const checkRunStatus = async (thread_id, run_id) => {
        const response = await fetch(
            `${OPENAI_BASE_URL}/threads/${thread_id}/runs/${run_id}`,
            {
                headers: HEADERS,
            },
        );
        const data = await response.json();

        if (data["status"] === "in_progress") {
            await new Promise((resolve) => setTimeout(resolve, 5000));
            return await checkRunStatus(thread_id, run_id);
        }
        if (data["status"] === "completed") {
            return await getMessages(thread_id);
        }
        if (data["status"] === "failed") {
            return null;
        }
    };

    const getMessages = async (thread_id) => {
        const res = await fetch(
            `${OPENAI_BASE_URL}/threads/${thread_id}/messages`,
            {
                headers: HEADERS,
            },
        );
        const data = await res.json();
        setLoading(false);
        return data.data[0].content[0].text.value;
    };

    const annotate = async (event) => {
        event.preventDefault();
        setLoading(true);

        const body = {
            assistant_id: ASSISTANT_ID,
            thread: {
                messages: [
                    {
                        role: "user",
                        content: formData.input + "   GND Subjects list variable should be called GND_Subjects, GND Code - Code, GND Name - Name",
                    },
                ],
            },
        };

        try {
            const response = await fetch(`${OPENAI_BASE_URL}/threads/runs`, {
                method: "POST",
                headers: HEADERS,
                body: JSON.stringify(body),
            });
            const data = await response.json();
            const res = await checkRunStatus(data["thread_id"], data["id"]);
            setMessages(JSON.parse(res).GND_Subjects);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom textAlign="center">
                    Subject indexer
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <form onSubmit={annotate}>
                    <Grid item xs={12} style={{marginTop: "20px"}}>
                        <TextField
                            label="Enter title and abstract"
                            name="input"
                            helperText={
                                <>
                                    Model will understand your input regardless you provide it.
                                </>
                            }
                            value={formData.input}
                            onChange={handleInputChange}
                            required
                            variant="outlined"
                            multiline
                            rows={6}
                            fullWidth
                        />
                    </Grid>
                    {loading && (
                        <div style={{textAlign: "center", padding: "20px"}}>
                            <CircularProgress color="primary"/>
                        </div>
                    )}
                    {messages && (
                        <List sx={{maxHeight: 300, overflowY: "auto"}}>
                            {messages.map((item) => (
                                <ListItem key={item.Code}>
                                    <ListItemText primary={item.Code + " - " + item.Name}/>
                                </ListItem>
                            ))}
                        </List>
                    )}
                    <div style={{marginTop: "20px"}}>
                        <Button variant="contained" color="primary" type="submit">
                            Annotate
                        </Button>
                    </div>
                </form>
            </Grid>
        </Grid>
    );
};

export default SubjectIndexer;
